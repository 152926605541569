@use '../../styles/partials/typography' as *;
@use '../../styles/partials/keyframes' as *;
@use '../../styles/partials/variables' as *;
@use '../../styles/partials/mixins' as *;

.button-background {
    background: rgba(255, 255, 255, 0.4);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(1.5px);
    -webkit-backdrop-filter: blur(1.5px);
    border-radius: 30px;
    border: 1px solid rgba(255, 255, 255, 0.18);
}

.mobile {

    @include tablet {
        display: none;
    }
}

.tablet {
    display: none;

    @include tablet {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100vh;
    }

    &__title {
        color: white;
        font-family: 'Title Font';
        font-size: 4.5rem;

        @include desktop {
            font-size: 5.5rem;
        }
    }

    &__text {
        color: white;
        font-family: 'Sub-heading Font';
        font-size: 2.5rem;
        text-align: center;

        width: 70%;
        margin: 5vw 0;

        @include desktop {
            width: 50%;
            font-size: 3.5rem;
        }
    }
}

.home {
    width: 100vw;
    height: 100vh;
    background: radial-gradient(ellipse at bottom, $home-grey 0%, $home-black 100%);
    overflow: hidden;

    &__picture {
        position: absolute;

        width: 100%;
        height: 25vh;

        display: flex;
        justify-content: center;
        align-items: center;

        overflow: hidden;

        &-img {
            background-image: url('../../assets/images/avm.jpg');
            background-position: center;
            background-size: cover;
            height: 120px;
            width: 120px;

            border-radius: 50%;

            z-index: 2;
        }
    }

    &__title {
        font-family: 'Title Font';
        font-size: 4rem;
        color: rgba(white, 0.8);
        line-height: 4.5rem;

        width: 100%;

        text-align: center;

        position: absolute;
        top: 23vh;

    }

    &__sub-title {
        position: absolute;
        top: 43vh;

        display: flex;
        justify-content: center;

        width: 100%;

        &-text {
            color: rgba(white, 0.7);
            font-family: 'Sub-heading Font';
            font-size: 1.3rem;
        }
    }

    &__socials {
        position: absolute;

        top: 48vh;
        width: 100%;

        padding: 2vw 20vw;

        display: flex;
        justify-content: space-between;
        align-items: center;

        &-separator {
            color: white;
            font-size: 2rem;

            padding-bottom: 3vw;
        }

        &-social {
            &--button {
                background: transparent;
                border: none;

            }
        }
    }

    &__buttons {
        position: absolute;

        top: 57vh;
        width: 100%;

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        padding: 0 3vw;

        &-neon {
            padding: 5vw 6vw;
            width: 50%;
            margin: 4vw 0;

            color: #03e9f4;
            font-family: 'Sub-heading Font';
            letter-spacing: .3rem;
            text-decoration: none;
            text-align: center;

            transition: 0.5s;
            overflow: hidden;

            cursor: pointer;

            background: rgba(255, 255, 255, 0.05);
            box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
            backdrop-filter: blur(2.5px);
            -webkit-backdrop-filter: blur(2.5px);

            &:hover {
                background: #03e9f4;
                color: #050801;
                box-shadow: 0 0 5px #03e9f4,
                    0 0 25px #03e9f4,
                    0 0 50px #03e9f4,
                    0 0 200px #03e9f4;
                -webkit-box-reflect: below 1px linear-gradient(transparent, #0005);
            }

            &:nth-child(1) {
                filter: hue-rotate(250deg);
            }

            &:nth-child(2) {
                filter: hue-rotate(110deg);
            }

            &--1 {
                position: relative;
                top: -21px;
                left: 0;
                width: 100%;
                height: 2px;
                background: linear-gradient(90deg, transparent, #03e9f4);
                animation: animate1 4s linear infinite;
            }

            &--2 {
                // position: r;
                top: -10px;
                right: 0px;
                // z-index: 3;
                width: 2px;
                height: 100px;
                // background-color: yellow;
                background: linear-gradient(180deg, transparent, #03e9f4);
                animation: animate2 4s linear infinite;
                animation-delay: 1.7s;
            }

            &--3 {
                position: relative;
                bottom: -42px;
                right: 0;
                width: 100%;
                height: 2px;
                background: linear-gradient(270deg, transparent, #03e9f4);
                animation: animate3 4s linear infinite;
                animation-delay: 2s;
            }

            &--4 {
                bottom: -100%;
                left: 0;
                width: 2px;
                height: 100%;
                background: linear-gradient(360deg, transparent, #03e9f4);
                animation: animate4 4s linear infinite;
                animation-delay: 3s;
            }
        }
    }
}

span {
    position: absolute;
    display: block;
}

.ship {
    position: absolute;
    right: 50%;
    top: 50%;
    margin-top: -55px;
    margin-right: -55px;
    height: 22px;
    background: rgba(0, 0, 0, .1);
    transform-origin: 0% 100% 0;
    z-index: 1;
    animation: ship $timing $curve2 infinite;

    .ship-rotate {
        position: absolute;
        height: 22px;
        transform: rotate(-110deg);
        animation: ship-rotate $timing $curve2 infinite;
    }

    .pod {
        position: absolute;
        top: 0;
        left: -8px;
        height: 16px;
        width: 16px;
        background: $ship;
        border-radius: 100% 0 100% 0;
        transform: rotate(-45deg);
    }

    .fuselage {
        position: absolute;
        top: 14px;
        left: -6px;
        height: 8px;
        width: 12px;
        background: $ship;
        border-radius: 100% 100% 0 0;

        &:after {
            content: "";
            position: absolute;
            left: 2px;
            top: 100%;
            width: 0;
            height: 0;
            border-left: 4px solid transparent;
            border-right: 4px solid transparent;
            border-top: 6px solid red;
        }
    }
}

.stars {
    width: 1px;
    height: 1px;
    background: transparent;
    box-shadow: $shadows-small;
    animation: animStar 50s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 1px;
        height: 1px;
        background: transparent;
        box-shadow: $shadows-small
    }
}

.stars2 {
    width: 2px;
    height: 2px;
    background: transparent;
    box-shadow: $shadows-medium;
    animation: animStar 100s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 2px;
        height: 2px;
        background: transparent;
        box-shadow: $shadows-medium;
    }
}

.stars3 {
    width: 3px;
    height: 3px;
    background: transparent;
    box-shadow: $shadows-big;
    animation: animStar 150s linear infinite;

    &:after {
        content: " ";
        position: absolute;
        top: 2000px;
        width: 3px;
        height: 3px;
        background: transparent;
        box-shadow: $shadows-big;
    }

}